import React, { useState } from "react"
//import GroupDetailsView from "../../components/media-output/lists/group-details-view/group-details-view"
import SearchGroupContent from "../../components/search/search-group-content/search-group-content"
//import SearchGroupEditContent from "../../components/search-groupedit-content"
import styles from "./index.module.scss"
import SearchLayout from "../../components/layout/search-layout/search-layout"
import auth from "../../services/auth"
import SearchGroupEditContent from "../../components/search/search-groupedit-content"

const ViewEditGroups = props => {
  console.log("current path: ", props.pathname)
  return (
    <SearchLayout location={props.location} searchPath="searchMine">
      <SearchGroupEditContent gid={props.location.search}></SearchGroupEditContent>
    </SearchLayout>
  )
}

export default ViewEditGroups
